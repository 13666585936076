@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

a {
  color: white;
  text-decoration: underline;
}

.hero-platform {
  min-height: 100dvh;
  background-color: #ebfbff;
  background-image: url(https://uploads-ssl.webflow.com/6374bf0d4ceb7472f4e99672/6374bf0d4ceb74a831e996cc_Hero%20Wave%20Company.svg);
  background-position: 0 0;
  background-position-x: 0px;
  background-position-y: 0px;
  background-size: cover;
  align-items: center;
  margin-top: -80px;
  padding: 120px 5vw 120px;
  display: flex;
}

.loading {
  background-color: #ebfbff;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 99999;
  content: "loading";
}

.loading::before,
.loading::after {
  content: "Loading";
}

.fancy {
  position: relative;
  overflow: hidden;
}

.fancy::before {
  content: "";
  background: #16a34a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -20%);
  aspect-ratio: 1;
  width: 50%;
  height: 2000px;
  animation: rotate 2s linear infinite;
}

.fancy::after {
  content: "";
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(0.5) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) scale(0.5) rotate(1turn);
  }
}

@layer base {
  :root {
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --dark-background: 224 71% 4%;
  }

  .light {
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --dark-background: 224 71% 4%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@media (color-gamut: p3) {
  @supports (color: oklch(0 0 0)) {
    :root {
      --ds-blue-100: oklch(22.17% 0.069 259.89);
      --ds-blue-200: oklch(25.45% 0.0811 255.8);
      --ds-blue-300: oklch(30.86% 0.1022 255.21);
      --ds-blue-400: oklch(34.1% 0.121 254.74);
      --ds-blue-500: oklch(38.5% 0.1403 254.4);
      --ds-blue-600: oklch(64.94% 0.1982 251.8131841760864);
      --ds-blue-700: oklch(57.61% 0.2321 258.23);
      --ds-blue-800: oklch(51.51% 0.2307 257.85);
      --ds-blue-900: oklch(71.7% 0.1648 250.79360374054167);
      --ds-blue-1000: oklch(96.75% 0.0179 242.4234217368056);

      --ds-red-100: oklch(22.1% 0.0657 15.11);
      --ds-red-200: oklch(25.93% 0.0834 19.02);
      --ds-red-300: oklch(31.47% 0.1105 20.96);
      --ds-red-400: oklch(35.27% 0.1273 21.23);
      --ds-red-500: oklch(40.68% 0.1479 23.16);
      --ds-red-600: oklch(62.56% 0.2277 23.03);
      --ds-red-700: oklch(62.56% 0.2234 23.03);
      --ds-red-800: oklch(58.01% 0.227 25.12);
      --ds-red-900: oklch(69.96% 0.2136 22.03);
      --ds-red-1000: oklch(95.6% 0.0293 6.61);

      --ds-amber-100: oklch(22.46% 0.0538 76.04);
      --ds-amber-200: oklch(24.95% 0.0642 64.78);
      --ds-amber-300: oklch(32.34% 0.0837 63.83);
      --ds-amber-400: oklch(35.53% 0.0903 66.29707162673735);
      --ds-amber-500: oklch(41.55% 0.1044 67.98);
      --ds-amber-600: oklch(75.04% 0.1737 74.49);
      --ds-amber-700: oklch(81.87% 0.1969 76.46);
      --ds-amber-800: oklch(77.21% 0.1991 64.28);
      --ds-amber-900: oklch(77.21% 0.1991 64.28);
      --ds-amber-1000: oklch(96.7% 0.0418 84.59);

      --ds-green-100: oklch(23.09% 0.0716 149.68);
      --ds-green-200: oklch(27.12% 0.0895 150.09);
      --ds-green-300: oklch(29.84% 0.096 149.25);
      --ds-green-400: oklch(34.39% 0.1039 147.78);
      --ds-green-500: oklch(44.19% 0.1484 147.2);
      --ds-green-600: oklch(58.11% 0.1815 146.55);
      --ds-green-700: oklch(64.58% 0.199 147.27);
      --ds-green-800: oklch(57.81% 0.1776 147.5);
      --ds-green-900: oklch(73.1% 0.2158 148.29);
      --ds-green-1000: oklch(96.76% 0.056 154.18);

      --ds-teal-100: oklch(22.1% 0.0544 178.74);
      --ds-teal-200: oklch(25.06% 0.062 178.76);
      --ds-teal-300: oklch(31.5% 0.0767 180.99);
      --ds-teal-400: oklch(32.43% 0.0763 180.13);
      --ds-teal-500: oklch(43.35% 0.1055 180.97);
      --ds-teal-600: oklch(60.71% 0.1485 180.24);
      --ds-teal-700: oklch(64.92% 0.1403 181.95);
      --ds-teal-800: oklch(57.53% 0.1392 181.66);
      --ds-teal-900: oklch(74.56% 0.1765 182.8);
      --ds-teal-1000: oklch(96.46% 0.056 180.29);

      --ds-purple-100: oklch(22.34% 0.0779 316.87);
      --ds-purple-200: oklch(25.91% 0.0921 314.41);
      --ds-purple-300: oklch(31.98% 0.1219 312.41);
      --ds-purple-400: oklch(35.93% 0.1504 309.78);
      --ds-purple-500: oklch(40.99% 0.1721 307.92);
      --ds-purple-600: oklch(55.5% 0.2191 306.12);
      --ds-purple-700: oklch(55.5% 0.2186 306.12);
      --ds-purple-800: oklch(48.58% 0.2102 305.73);
      --ds-purple-900: oklch(69.87% 0.2037 309.51);
      --ds-purple-1000: oklch(96.1% 0.0304 316.46);

      --ds-pink-100: oklch(22.67% 0.0628 354.73);
      --ds-pink-200: oklch(26.2% 0.0859 356.68);
      --ds-pink-300: oklch(31.15% 0.1067 355.93);
      --ds-pink-400: oklch(32.13% 0.1174 356.71);
      --ds-pink-500: oklch(37.01% 0.1453 358.39);
      --ds-pink-600: oklch(50.33% 0.2089 4.33);
      --ds-pink-700: oklch(63.52% 0.2346 1.01);
      --ds-pink-800: oklch(59.51% 0.2429 4.21);
      --ds-pink-900: oklch(69.36% 0.2223 3.91);
      --ds-pink-1000: oklch(95.74% 0.0326 350.08);
    }
  }
}

:root {
  --ds-background-100: 0 0% 4%;
  --ds-background-200: 0 0% 0%;

  --ds-gray-100: 0 0% 10%;
  --ds-gray-200: 0 0% 12%;
  --ds-gray-300: 0 0% 16%;
  --ds-gray-400: 0 0% 18%;
  --ds-gray-500: 0 0% 27%;
  --ds-gray-600: 0 0% 53%;
  --ds-gray-700: 0 0% 56%;
  --ds-gray-800: 0 0% 49%;
  --ds-gray-900: 0 0% 63%;
  --ds-gray-1000: 0 0% 93%;

  --ds-blue-100: hsl(216, 50%, 12%);
  --ds-blue-200: hsl(214, 59%, 15%);
  --ds-blue-300: hsl(213, 71%, 20%);
  --ds-blue-400: hsl(212, 78%, 23%);
  --ds-blue-500: hsl(211, 86%, 27%);
  --ds-blue-600: hsl(206, 100%, 50%);
  --ds-blue-700: hsl(212, 100%, 48%);
  --ds-blue-800: hsl(212, 100%, 41%);
  --ds-blue-900: hsl(210, 100%, 66%);
  --ds-blue-1000: hsl(206, 100%, 96%);

  --ds-red-100: hsl(357, 37%, 12%);
  --ds-red-200: hsl(357, 46%, 16%);
  --ds-red-300: hsl(356, 54%, 22%);
  --ds-red-400: hsl(357, 55%, 26%);
  --ds-red-500: hsl(357, 60%, 32%);
  --ds-red-600: hsl(358, 75%, 59%);
  --ds-red-700: hsl(358, 75%, 59%);
  --ds-red-800: hsl(358, 69%, 52%);
  --ds-red-900: hsl(358, 100%, 69%);
  --ds-red-1000: hsl(353, 90%, 96%);

  --ds-amber-100: hsl(35, 100%, 8%);
  --ds-amber-200: hsl(32, 100%, 10%);
  --ds-amber-300: hsl(33, 100%, 15%);
  --ds-amber-400: hsl(35, 100%, 17%);
  --ds-amber-500: hsl(35, 91%, 22%);
  --ds-amber-600: hsl(39, 85%, 49%);
  --ds-amber-700: hsl(39, 100%, 57%);
  --ds-amber-800: hsl(35, 100%, 52%);
  --ds-amber-900: hsl(35, 100%, 52%);
  --ds-amber-1000: hsl(40, 94%, 93%);

  --ds-green-100: hsl(136, 50%, 9%);
  --ds-green-200: hsl(137, 50%, 12%);
  --ds-green-300: hsl(136, 50%, 14%);
  --ds-green-400: hsl(135, 70%, 16%);
  --ds-green-500: hsl(135, 70%, 23%);
  --ds-green-600: hsl(135, 70%, 34%);
  --ds-green-700: hsl(131, 41%, 46%);
  --ds-green-800: hsl(132, 43%, 39%);
  --ds-green-900: hsl(131, 43%, 57%);
  --ds-green-1000: hsl(136, 73%, 94%);

  --ds-teal-100: hsl(169, 78%, 7%);
  --ds-teal-200: hsl(170, 74%, 9%);
  --ds-teal-300: hsl(171, 75%, 13%);
  --ds-teal-400: hsl(171, 85%, 13%);
  --ds-teal-500: hsl(172, 85%, 20%);
  --ds-teal-600: hsl(172, 85%, 32%);
  --ds-teal-700: hsl(173, 80%, 36%);
  --ds-teal-800: hsl(173, 83%, 30%);
  --ds-teal-900: hsl(174, 90%, 41%);
  --ds-teal-1000: hsl(166, 71%, 93%);

  --ds-purple-100: hsl(283, 30%, 12%);
  --ds-purple-200: hsl(281, 38%, 16%);
  --ds-purple-300: hsl(279, 44%, 23%);
  --ds-purple-400: hsl(277, 46%, 28%);
  --ds-purple-500: hsl(274, 49%, 35%);
  --ds-purple-600: hsl(272, 51%, 54%);
  --ds-purple-700: hsl(272, 51%, 54%);
  --ds-purple-800: hsl(272, 47%, 45%);
  --ds-purple-900: hsl(275, 80%, 71%);
  --ds-purple-1000: hsl(281, 73%, 96%);

  --ds-pink-100: hsl(335, 32%, 12%);
  --ds-pink-200: hsl(335, 43%, 16%);
  --ds-pink-300: hsl(335, 47%, 21%);
  --ds-pink-400: hsl(335, 51%, 22%);
  --ds-pink-500: hsl(335, 57%, 27%);
  --ds-pink-600: hsl(336, 75%, 40%);
  --ds-pink-700: hsl(336, 80%, 58%);
  --ds-pink-800: hsl(336, 74%, 51%);
  --ds-pink-900: hsl(341, 90%, 67%);
  --ds-pink-1000: hsl(333, 90%, 96%);
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply antialiased;
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
